import React, { useState } from 'react'
import './Testimonials.css'
import { testimonialsData } from '../../Data/testimonialsData'
import tleftarrow from '../../assets/t-leftarrow.png'
import trightarrow from '../../assets/t-rightarrow.png'

const Testimonials = () => {
  const [selected,setSelected]=useState(0);
  const tLength= testimonialsData.length;
  return (
    <div className='Testimonials'>
      <div className="left-t">
        <span>Testimonials</span>
        <span className='stroke-text'>People's Review</span>
        <span>About Us</span>
        <span>
          {testimonialsData[selected].review}
        </span>
        <span>
           <span style={{color:'rgb(152,181,249)'}}>
                  {testimonialsData[selected].name}
           </span>{" "}
           - {testimonialsData[selected].status}
        </span>
      </div>
      <div className="right-t">
      <div></div>
      <div></div>
        <img src={testimonialsData[selected].image} alt="" />
        <div className='t-arrows'>
        <img 
        onClick={()=>
        selected===0?setSelected(tLength-1):setSelected((prev)=>prev-1)} 
        src={tleftarrow} alt="" />
        <img onClick={()=>
        selected===tLength-1?setSelected(0):setSelected((next)=>next+1)} src={trightarrow} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Testimonials
