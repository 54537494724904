import React from 'react'
import './Reasons.css'

import Reason1 from "../../assets/Reason1.jpg"
import Reason2 from "../../assets/Reason2.jpg"
import Reason3 from "../../assets/Reason3.jpg" 
import Reason4 from "../../assets/Reason4.jpg"
import tick from "../../assets/Blue_tick.png"
import partner1 from "../../assets/nike.png"
import partner2 from "../../assets/adidas.png"
const Reasons = () => {
  return (
    <div className="Reasons">
         <div className="left-r">
           <img src={Reason1} alt="" />
           <img src={Reason2} alt="" />
           <img src={Reason3} alt="" />
           <img src={Reason4} alt="" />
         </div>
         <div className="right-r">
          <span> some reasons</span>
          <div>
          <span className="stroke-text"> why</span>
          <span> choose us?
          </span>
          </div>
          
           <div className='details-r'>
           <div>
           <img src={tick} alt=""/>
           <span> Provide ONE Day Free Trail with full facilities.</span>
           </div>
          <div>
          <img src={tick} alt="" />
          <span>Our facility and equipments are disinfected, cleaned and sanitized regularly.</span>
          </div>
          <div>
          <img src={tick} alt="" />
          <span>Located in city center.</span>
          </div>
          <div>
          <img src={tick} alt="" />
          <span>Over 50+ Trainers to help and support you.</span>
          </div>
          <div>
          <img src={tick} alt="" />
          <span>300+ Satisfied Members and Executive Members</span>
          </div>
         </div>
         <div className='partner-main'>
         <div style={{color:'whitesmoke', fontWeight:'normal',fontSize:'20px'}}>OUR PARTENERS</div> 
        <div className="partners">
        <img src={partner1} alt="" />
        <img src={partner2} alt="" />
        </div>
         </div>
        
    </div>
    </div>
  )
}

export default Reasons;
