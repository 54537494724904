import React,{useRef} from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useForm} from 'react-hook-form'

const Join = () => {
  const form =useRef();
  const {reset}=useForm();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_b378amr', 'template_8qxwkxs', form.current, 'OTZslzkiWDm76V9EE')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  const notify=()=>toast("Message Sent");
  const onFinish=()=>{
    form.resetFields()
  }
  // const wreset=()=>reset();
  return (
 <div className="Join" id="join-us">
    <div className="left-j">
      <span>
           <span className='stroke-text'>Ready To </span>
           <span> Level Up </span>
      </span>
      <span>
           <span>With Us?</span>
           <span className='stroke-text'> EMAIL Us</span>
      </span>
    </div>
    <div className="right-j">
      <form onFinish={onFinish} ref={form} action="" className="email-container" onSubmit={sendEmail}>
        <input type="email" name='user_email' placeholder='Enter Your Email Here'/>
         
         <button  onClick={notify} className='btn btn-j'>Email Now</button>
      </form>
    </div>
    <ToastContainer/>
 </div>
 
  )
}

export default Join
