import React from 'react'
import './Header.css'
import Logo from '../../assets/Logo.svg'
import menubar from '../../assets/menubar.png'
const Header = () => {
  
  return (
    <div className='header'>
    <div className='menubar'>
      <img src={menubar} alt="" />
    </div>
   <div className='menu'>
    <img className='logo' src={Logo} alt=""/>

    <ul className='header-menu'>
      <li>Home</li>
      <li>Programs</li>
      <li>Why us</li>
      <li>Plans</li>
      <li>Testimonials</li>
    </ul>
    </div>
    </div>
  )
}

export default Header;
