import image1 from "../assets/t-image1.jpg";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the King Gym and by choosing the right plan and program I already achieved my ideal body!",
    name: 'Vivek Khausal',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'Fitness was ,is and will always be my first priority and King Gym have always thaught me to over all the milestones to achieve it',
    name: 'Ritesh Joshi',
    status: 'COACH'
  },
  {
    image : image3,
    review:'I am MORE Determined ,MORE Consistant ,MORE Healthy and MORE physically as well as mentally active....Thanks to King Gym',
    name: 'Nitesh Gautam',
    status: "CUSTOMER"
  }
];