import React from 'react'
import "./Plans.css"
import whitetick from '../../assets/whitetick.png'
import { plansData } from '../../Data/plansData'
const Plans = () => {
  return (
    <div>
      <div className="plans-container">
        <div className="programs-header">
          <span  className='stroke-text'>Ready To</span>
          <span>Build Yourself</span>
          <span  className='stroke-text'>Withus</span>
        </div>
        <div className="plans">
          {plansData.map((plan,i)=>(
            <div className="plan" key={i}>
              {plan.icon}
              <span>{plan.name}</span>
              <span>₹ {plan.price}</span>
              <div className="features">
                {plan.features.map((feature,i)=>
                  <div className="feature">
                    <img src={whitetick} alt="" />
                    <span key={i}>{feature}</span>
                  </div>
                )}
              </div>
              <div><span>See more benefits</span></div>
              <button className='btn'>Join Now</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Plans
