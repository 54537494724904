import React from 'react'
import Header from '../Header/Header'
import heroimage from '../../assets/hero-image.png'
import heartrate from '../../assets/heartrate.png'
import heroimagebg from '../../assets/hero-image-bg.png'
import calories from '../../assets/calories.jpg'
import './Hero.css'
import { useAuth0 } from "@auth0/auth0-react";
import {motion} from 'framer-motion'
const Hero = () => {
  const transition={type: 'spring' ,duration: 3};
  const {loginWithRedirect,logout,user,isAuthenticated} = useAuth0();
  return (
    <div className='hero'>
    <div className="left-h">
      <Header/>

      <div className="thebestad">
        <motion.div>
          {/* initial={{left:'238px'}}
          whileInView={{left:'8px'}}
          transition={transition} */}
        </motion.div>
        <span>the best fitness club in the city </span>
      </div>
      <div className="hero-text">
        <div><span>GET YOUR</span></div>
        <div><span className='stroke-text'>MONSTER </span><span>OUT</span></div>
      </div>
      <div><span>We will help you build your dream body and motivate you to live 
      your life to the fullest .</span></div>

      <div className='figures'>
        <div>
        <span>+140</span>
        <span>expert coaches</span>
        </div>
        <div>
        <span>+500</span>
        <span>members joined</span>
        </div>
        <div>
        <span>+50</span>
        <span>fitness programs</span>
        </div>
        <div className='calories'>
        <img className="calories-pic" src={calories} alt="" />
        <div>
        <span>Calories Burned</span>
        <span>220 kcal</span>
        </div>
        </div>
      </div>

      <div className="hero-buttons">
        <buttons className="btn" type='button'>Get Started</buttons>
        <buttons className="btn" type='button' >Learn More</buttons>
      </div>
    </div>
    <div className="right-h">
    <ul>
    
    {isAuthenticated ? (
      <div> {user.email}</div>): <p></p>
    }
    
    {isAuthenticated ?(
    <li>
    <button className='btn' onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      Log Out
    </button></li>
    ): (<li>
    <button className='btn' onClick={() => loginWithRedirect()}>Join Now</button>
    </li>) }
    </ul>
      <div className="heart-rate">
      <img src={heartrate} alt="" />
      </div>
      
      <img src={heroimage} alt="" className='heroimage'/>
      <img src={heroimagebg} alt="" className='heroimagebg'/>

      <div className="calories">
      
      
      </div>
    </div>
    </div>
  )
}

export default Hero;
