import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import { ChakraProvider } from '@chakra-ui/react'


  const root=ReactDOM.createRoot(document.getElementById('root'));
  root.render(  
    
  <Auth0Provider
    domain="king-gym.us.auth0.com"
    clientId="zc5xpMLStxFiWqP4ETshr8GU8t4KT5rE"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
    <App />
  </Auth0Provider>);


 