import React from 'react'
import './Footer.css'
import github from '../../assets/github.png'
import instagram from '../../assets/instagram.png'
import linkedin from '../../assets/linkedin.png'
import Logo from '../../assets/Logo.svg'
const Footer = () => {
  return (
    <div className='Footer-container'>
      <hr />
       <div className="footer">
       <div className="social">
           <img src={github} alt="" />
            <img src={instagram} alt="" />
                <img src={linkedin} alt="" />
           </div>
       
          <div className="Logo-f">
          <span>The King Gym</span> 
             <img src={Logo} alt="" />
           <div className='Address'>
            <span>Adddress: </span>
            <span>G-Block Mahindra Enclave Shastri Nagar ,Ghaziabad</span>
           </div>
             
          </div> 
          
       </div>
    </div>
  )
}

export default Footer
